import { ref, onMounted, onUnmounted, nextTick } from 'vue'
import type { UserInfo } from '~/types/api.js'

export const useUser = () => {
  const avatarSrc = ref<string>('')
  const userName = ref<string>('')

  const setUserInfo = (userInfo: UserInfo) => {
    avatarSrc.value = userInfo.photo
    userName.value = `${userInfo.prenom} ${userInfo.nom}`
  }

  const updateUserInfo = () => {
    const userInfo = localStorage.getItem('user')
    if (userInfo) {
      const user = JSON.parse(userInfo)
      setUserInfo(user)
    }
  }

  return {
    avatarSrc,
    userName,
    updateUserInfo,
    setUserInfo
  }
} 