import type { UserInfo, AuthResponse } from '~/types/auth'
import { useRouter } from 'nuxt/app'
import { useUser } from '~/composables/useUser'
export const useAuth = () => {
  const router = useRouter()
  const user = useState<UserInfo | null>('user', () => null)
  const { updateUserInfo, setUserInfo } = useUser()
  
  // Configuration du cookie avec les bonnes options
  const token = useCookie('auth_token', {
    maxAge: 60 * 60 * 24 * 7, // 7 jours
    path: '/',
    secure: true,
    sameSite: 'lax'
  })

  const checkAuth = () => {
    return !!token.value
  }

  const handleLinkedInCallback = async (code: string) => {
    try {

      const config = useRuntimeConfig()

      // Appel direct au backend .NET 8
      const response = await $fetch<AuthResponse>(`${config.public.apiBase}/auth/linkedin`, {
        method: 'POST',
        body: { code },
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include'
      })

      console.log('Réponse API:', response)

      if (!response) {
        throw new Error('Pas de données reçues de l\'API')
      }

      // Stockage des données utilisateur dans le localStorage via useState
      user.value = response.user
      // Stockage du token dans le cookie
      token.value = response.token
      
      setUserInfo(response.user);
      // updateUserInfo();
      
      // Stockage additionnel dans le localStorage pour persistance
      localStorage.setItem('user', JSON.stringify(response.user))
      
      return true


    } catch (error) {
      console.error('Erreur LinkedIn:', error)
    }
  }

  // Ajout d'une fonction pour restaurer la session au chargement
  const initAuth = () => {
    const savedUser = localStorage.getItem('user')
    if (savedUser && token.value) {
      user.value = JSON.parse(savedUser)
    }
  }

  const logout = async () => {
    try {
      // Vider le localStorage
      localStorage.clear() // ou localStorage.removeItem('token') pour supprimer uniquement le token
      
      // Réinitialiser l'état utilisateur
      user.value = null
      
      // Nettoyer les cookies si vous en utilisez
      if (token.value) {
        token.value = null
      }
      
      // Forcer le rechargement des composables qui utilisent useFetch
      await refreshNuxtData()
      
      // Redirection vers la page de login
      await navigateTo('/login')
    } catch (error) {
      console.error('Erreur lors de la déconnexion:', error)
    }
  }

  const isAuthenticated = computed(() => !!user.value && !!token.value)

  return {
    user,
    token,
    handleLinkedInCallback,
    logout,
    isAuthenticated,
    initAuth
  }
}
